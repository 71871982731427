import httpClient from '@/utils/http-client'

export function getDesignerList (params) {
  const url = '/d/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function getDesignerInfo (params) {
  const url = '/d/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
