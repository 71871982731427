<template>
  <div class="village">
    <div class="village-body">
      <div class="poster"
           :class="{ gnbHidden }">
        <img src="@/assets/poster.jpg" alt="Village" />
      </div>

      <div class="content">
        <h1>
          제 31회 경희대학교 시각디자인학과<br />
          온 오프라인 졸업 전시회
        </h1>
        <p>
          <b>장소</b> &nbsp;I&nbsp;  경희대학교 예술디자인대학 A&D Hall<br />
          <b>일시</b>  &nbsp;I&nbsp;  2022.11.23 Wed- 27 Sun<br />
          <b>오프닝</b>  &nbsp;I&nbsp;  2022.11.23 Wed 5pm<br />
          <b>문의</b>  &nbsp;I&nbsp;  031.201.2051<br />
          <b>인스타그램</b>  &nbsp;I&nbsp;  <a href="https://www.instagram.com/khvd_graduation/" target="_blank">khvd-graduation</a>
        </p>

        <img class="dot"
             src="@/assets/village-dot.svg"
             alt="" />

        <h2>
          각자의 작품이 모여 하나의 마을을 형성한다.
        </h2>
        <p>
          2022년도 제 31회 경희대학교 시각디자인학과 졸업전시회 주제는 &lt;Village&gt;입니다. &nbsp;제각기 다른 개성을 가진 각각의 작품이 한 데 모여, 하나의 마을을 형성한다는 의미를 담아 이번 졸업전시회를 Village라고 칭했습니다.<br />
          <br />
          우리는 이 Village를 형성하기까지 많은 시행착오를 겪었습니다. 끝을 알 수 없는 계단처럼 수많은 시련을 겪고 결과를 알 수 없는 다양한 문을 열어보면서 각자의 작품을 만들어왔습니다. 작품들은 마을에 모여 다채로움을 만들어 내고, 각자의 방식으로 이야기를 들려줍니다. 우리의 Village는 각자의 노력이 깃들어져 있는 작품들로 형성되어 있고, 마을의 형태로 자리 잡아 이번 전시를 통해 여러분께 선보이고자 합니다. 우리는 앞으로 각자의 길을 찾아 나아가겠지만 우리가 걸어온 발걸음의 흔적은 앞으로도 계속, 이 마을에 남아있을 것입니다.<br />
          <br />
          이제 마을의 문이 열렸습니다.<br />
          지금, 우리의 빌리지로 초대합니다! WELCOME TO OUR VILLAGE
        </p>

        <h2 class="category">
          Project Category를 소개드립니다.
        </h2>
        <img src="@/assets/village-category.svg" alt="" />
        <ul class="category">
          <li>
            <h4>
              Graphic Design
            </h4>
            <p>
              시각 정보 전달을 목적으로 니즈를 분석하고 디자인 콘셉트에 맞는 아이디어 발상과 시각화, 매체별 다양한 기법을 활용하여 창의적 표현 및 전달하는 능력을 강화합니다.
            </p>
          </li>
          <li>
            <h4>
              Branding & Identity
            </h4>
            <p>
              브랜드의 비전, 가치를 시각적으로 상징화 및 시스템화합니다. 그리고 디자인 접근 방법을 통해 고객 접점에서 브랜드에 필요한 시각물을 구현하고 브랜드 경험으로 확장시킵니다.
            </p>
          </li>
          <li>
            <h4>
              Design Business
            </h4>
            <p>
              디자인 역할의 이해와 디자인 방법론을 학습하며 지속가능한 비즈니스 아이디어 구상 및 모델 설계를 통해 기획, 마케팅, 비즈니스 분야로 확장해 나갑니다.
            </p>
          </li>
          <li>
            <h4>
              UX/UI
            </h4>
            <p>
              디지털 디자인, UX디자인 등의 인터렉션 디자인 분야로, 사용자 상호작용의 중심에서 효율적으로 정보를 경험할 수 있는 방식과 체계를 시각적 채널을 중심으로 구축합니다.
            </p>
          </li>
        </ul>
        <div class="button">
          <router-link to="/projects/graphic">
            <img src="@/assets/village-project.svg" alt="" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="village-footer">
      <h2>
        <img src="@/assets/logo.svg" alt="Village" /><br />
        오시는 길 &nbsp;MAP
      </h2>
      <div class="map">
        <div class="map-wrap">
          <div class="iframe-wrap">
            <iframe src="https://www.google.com/maps/d/embed?mid=1xUc1k4rcnsYawYNBWWiiWhu1KIwrtNY&ehbc=2E312F" width="100%" height="594"></iframe>
          </div>
        </div>
        <div class="map-description">
          <p class="address">
            경희대학교 예술디자인대학 A&D Hall<br />
            경기도 용인시 기흥구 덕영대로 1732
          </p>
          <p>
            Kyung Hee University College of<br>
            Art and Design A&D Hall<br>
            1732, Deokyeong-daero, Giheung-gu,<br>
            Yongin-si, Gyeonggi-do
          </p>
          <img src="@/assets/village-dotdot.svg" alt="Village" />
        </div>
      </div>
    </div>

    <div class="footer-graphic">
      <span class="marquee-content">
        <img src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
        <img aria-hidden="true" src="@/assets/village-graphic.svg" />
      </span>
      <span class="marquee-content"
            aria-hidden="true">
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
        <img src="@/assets/village-graphic.svg" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VillagePage',

  computed: {
    ...mapState({
      gnbHidden: 'gnbHidden'
    })
  }
}
</script>

<style lang="scss" scoped>
.village {
  position: relative;
  max-width: 1612px;
  margin: 0 auto;

  .village-body {
    padding-top: 75px;

    @media (max-width: 600px) {
      padding-top: 25px;
    }

    &::after {
      content: '';
      clear: both;
      display: block;
    }

    .poster {
      float: left;
      width: 47%;
      padding: 0 60px;
      box-sizing: border-box;
      position: sticky;
      top: 120px;
      transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

      &.gnbHidden {
        top: 30px;
      }

      @media (max-width: 980px) {
        padding: 0 20px;
      }

      @media (max-width: 600px) {
        position: static;
        width: auto;
        float: none;
        padding-bottom: 60px;
      }

      img {
        width: 100%;
        max-width: 604px;
      }
    }

    .content {
      float: right;
      width: 53%;
      padding: 0 60px;
      box-sizing: border-box;

      @media (max-width: 980px) {
        padding: 0 20px;
      }

      @media (max-width: 600px) {
        position: static;
        width: auto;
      }

      h1 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 66px;

        @media (max-width: 980px) {
          font-size: 25px;
          line-height: 30px;
          margin-bottom: 25px;
        }
      }

      p {
        text-transform: uppercase;
        font-size: 25px;
        line-height: 35px;
        font-weight: 300;

        @media (max-width: 980px) {
          font-size: 18px;
          line-height: 28px;
        }

        b {
          font-weight: 400;
        }

        a {
          border-bottom: 1px solid #000000;
        }
      }

      img {
        max-width: 100%;

        &.dot {
          margin: 85px 0 20px;
        }
      }

      h2 {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 32px;

        @media (max-width: 980px) {
          font-size: 20px;
          margin-bottom: 20px;
        }

        &.category {
          margin-top: 200px;

          @media (max-width: 980px) {
            margin-top: 110px;
          }
        }
      }

      ul.category {
        margin: 70px 0 90px;

        li {
          margin-bottom: 40px;

          h4 {
            display: inline-block;
            vertical-align: top;
            width: 30%;
            font-weight: 600;
            font-size: 23px;
            line-height: 27px;

            @media (max-width: 980px) {
              font-size: 16px;
              line-height: 19px;
            }
          }

          p {
            margin-left: 20px;
            display: inline-block;
            vertical-align: top;
            width: calc(70% - 25px);
            font-weight: 300;
            font-size: 20px;
            line-height: 1.5;

            @media (max-width: 980px) {
              font-size: 15px;
              line-height: 23px;
            }
          }
        }
      }

      .button {
        text-align: right;

        img {
          @media (max-width: 980px) {
            width: 242px;
          }
        }
      }
    }
  }

  .village-footer {
    padding: 220px 60px 140px;

    @media (max-width: 980px) {
      padding: 130px 0 50px;
    }

    h2 {
      background: #000000;
      color: #FFFFFF;
      padding: 50px 0 40px;
      font-weight: 700;
      font-size: 30px;
      text-align: center;

      @media (max-width: 980px) {
        padding: 50px 0 40px;
        font-size: 18px;
      }

      img {
        margin-bottom: 7px;
        width: 335px;
        height: 30px;

        @media (max-width: 980px) {
          width: 183px;
          height: 16px;
        }
      }
    }

    .map {
      margin-top: 120px;

      @media (max-width: 980px) {
        padding: 0 20px;
        margin-top: 0;
      }

      &::after {
        content: '';
        clear: both;
        display: block;
      }

      .map-wrap {
        float: left;
        width: calc(100% - 500px);
        height: 524px;
        margin-right: 30px;
        background-color: #DDDDDD;
        overflow: hidden;

        @media (max-width: 980px) {
          float: none;
          width: 100%;
          height: 197px;
          margin: 50px 0;
        }

        .iframe-wrap {
          margin-top: -60px;

          iframe {
            height: 584px;

            @media (max-width: 980px) {
              height: 257px;
            }
          }
        }
      }

      .map-description {
        float: left;
        position: relative;
        width: 470px;
        height: 524px;

        @media (max-width: 980px) {
          float: none;
          width: 100%;
          height: auto;
        }

        p {
          font-size: 20px;
          font-weight: 300;
          line-height: 28px;

          @media (max-width: 980px) {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
          }

          &.address {
            font-size: 30px;
            font-weight: 700;
            line-height: 40px;

            @media (max-width: 980px) {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 15px;
            }
          }
        }

        img {
          position: absolute;
          bottom: 0;
          left: 0;

          @media (max-width: 980px) {
            position: static;
            max-width: 100%;
            margin: 80px 0 140px;
          }
        }
      }
    }
  }

  .footer-graphic {
    height: 60px;
    background: url('@/assets/village-graphic-bg.svg');
    background-size: cover;
    overflow: hidden;
    white-space: nowrap;
    --gap: 1rem;
    display: flex;
    user-select: none;
    gap: 70px;

    img {
      width: 278px;
      height: 28px;
      margin-top: 16px;
    }

    .marquee-content {
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;
      min-width: 100%;
      gap: 70px;
      animation-name: scroll;
      animation-duration: 60s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    @keyframes scroll {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
  }
}
</style>
