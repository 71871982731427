<template>
  <div class="blank">
    <div class="loading">
      <img src="@/assets/not-found.svg"
           alt="" />
      <p>
        찾을 수 없습니다
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlankPage',

  created () {

  },

  methods: {

  },

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.blank {
  .loading {
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    p {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 300;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
</style>
