<template>
  <div class="thanks-to">
    <div class="list-header">
      <h2>Thanks to</h2>
    </div>

    <div class="list-body">
      <h3>
        전임교수 & 지도교수
      </h3>

      <ul>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/1.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 장미경
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/2.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 김형석
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/3.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 김은정
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/4.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 정지연
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/5.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 박상희
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
          <div class="profile"
               :style="{ backgroundImage: `url('${staticUrl}/images/prof/6.jpg')` }">
            <img src="@/assets/profile.png" alt="" />
          </div>
          <div class="name">
            <span class="kor">
              Prof. 김도형
            </span>
          </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/7.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 김정우
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="a">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/prof/8.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                Prof. 정지혜
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="list-footer">
      <h3>
        졸업전시준비위원회
      </h3>
      <img :src="`${staticUrl}/images/prof/0.jpg`" alt="" />
      <ul>
        <li class="master">
          <h4>
            졸업준비 위원장
          </h4>
          <p>
            최지민
          </p>
        </li>
        <li class="master">
          <h4>
            졸업준비 부위원장
          </h4>
          <p>
            신명주
          </p>
        </li>
        <li>
          <h4>
            기획
          </h4>
          <p>
            팀장 &nbsp;I&nbsp; 이연주<br>
            팀원 &nbsp;I&nbsp; 박성현<br>
            박주연<br>
            신명주<br>
            전주연
          </p>
        </li>
        <li>
          <h4>
            그래픽
          </h4>
          <p>
            팀장 &nbsp;I&nbsp; 우지민<br>
            팀원 &nbsp;I&nbsp; 신명주<br>
            최민경<br>
            최지민<br>
            황미진
          </p>
        </li>
        <li>
          <h4>
            웹디자인
          </h4>
          <p>
            팀장 &nbsp;I&nbsp; 천민정<br>
            팀원 &nbsp;I&nbsp; 우진아
          </p>
        </li>
        <li>
          <h4>
            촬영
          </h4>
          <p>
            팀장 &nbsp;I&nbsp; 전주연<br>
            팀원 &nbsp;I&nbsp; 김나영<br>
            박주연
          </p>
        </li>
      </ul>

      <div class="developer">
        <h4>
          웹사이트 개발
        </h4>
        <p>
          안병훈
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { staticUrl } from '@/utils/tools'

export default {
  name: 'ThanksTo',

  computed: {
    staticUrl () {
      return staticUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.thanks-to {
  max-width: 1612px;
  margin: 0 auto;

  .list-header {
    padding: 180px 0 200px;

    @media (max-width: 980px) {
      padding: 70px 0 100px;
    }

    h2 {
      font-size: 50px;
      font-weight: 800;
      text-align: center;

      @media (max-width: 980px) {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  .list-body {
    padding: 0 45px;

    @media (max-width: 980px) {
      padding: 0 12px;
    }

    h3 {
      font-size: 30px;
      font-weight: 500;
      padding: 0 15px;
      margin-bottom: 20px;

      @media (max-width: 980px) {
        padding: 0 7px;
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 25%;

        @media (max-width: 1280px) {
          width: 33.333333%;
        }

        @media (max-width: 800px) {
          width: 50%;
        }

        .a {
          display: block;
          padding: 0 15px 110px;
          transition: transform 0.15s cubic-bezier(0.65, 0, 0.35, 1);

          @media (max-width: 980px) {
            padding: 0 7px 45px;
          }

          &:hover {
            transform: scale(1.08);
            transform-origin: center center;

            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }
            }

            .name {
              padding: 20px 0 20px 25px;
              background-color: #FFF16E;

              @media (max-width: 800px) {
                padding: 15px 0 15px 20px;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              width: 100%;
              height: auto;
            }

            &::before {
              content: "";
              position: absolute;
              top: -85px;
              left: -85px;
              width: 85px;
              height: 85px;
              background: url('@/assets/profile-top.svg');
              background-size: 100% 100%;
              transition: top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              left 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                top: -46px;
                left: -46px;
                width: 46px;
                height: 46px;
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -80px;
              right: -80px;
              width: 80px;
              height: 80px;
              background: url('@/assets/profile-bottom.svg');
              background-size: 100% 100%;
              transition: bottom 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              right 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                bottom: -44px;
                right: -44px;
                width: 44px;
                height: 44px;
              }
            }
          }

          .name {
            padding: 20px 0;
            font-size: 1.3vw;
            transition: padding 0.15s cubic-bezier(0.65, 0, 0.35, 1),
            background-color 0.15s ease;

            @media (min-width: 1920px) {
              font-size: 25px;
            }

            @media (max-width: 1280px) {
              font-size: 1.95vw;
            }

            @media (max-width: 800px) {
              padding: 15px 0;
            }


            .kor {
              white-space: nowrap;
              font-weight: 500;

              @media (max-width: 800px) {
                display: block;
                font-size: 18px;
              }
            }

            .eng {
              white-space: nowrap;
              padding-left: 1.146vw;
              font-weight: 200;

              @media (max-width: 800px) {
                display: block;
                font-size: 15px;
                padding-top: 5px;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .list-footer {
    text-align: center;
    padding: 285px 60px 0;

    @media (max-width: 980px) {
      padding: 70px 20px 0;
    }

    h3 {
      font-weight: 500;
      font-size: 30px;
      padding-bottom: 30px;

      @media (max-width: 980px) {
        font-size: 18px;
        padding-bottom: 20px;
      }
    }

    img {
      width: 100%;
      max-width: 1080px;
    }

    ul {
      max-width: 1494px;
      margin: 0 auto;

      &::after {
        content: "";
        clear:both;
        display: block;
      }

      li {
        float: left;
        width: 25%;
        margin-top: 150px;

        @media (max-width: 980px) {
          margin-top: 90px;
        }

        @media (max-width: 600px) {
          width: 50%;
        }

        &.master {
          float: none;
          width: 100%;
          margin-top: 55px;

          @media (max-width: 980px) {
            margin-top: 30px;
          }

          h4 {
            padding-bottom: 20px;
          }

          p {
            text-align: center;
          }
        }

        h4 {
          font-weight: 500;
          font-size: 25px;
          padding-bottom: 30px;

          @media (max-width: 980px) {
            font-size: 15px;
            padding-bottom: 15px;
          }
        }

        p {
          display: inline-block;
          text-align: right;
          font-weight: 300;
          font-size: 25px;
          line-height: 1.85em;

          @media (max-width: 980px) {
            font-size: 15px;
          }
        }
      }
    }

    .developer {
      max-width: 1494px;
      margin: 160px auto;
      border-top: 1.5px solid #999999;
      padding-top: 120px;
      color: #707070;

      @media (max-width: 980px) {
        margin: 65px auto;
        padding-top: 35px;
      }

      h4 {
        font-weight: 500;
        font-size: 25px;
        padding-bottom: 30px;

        @media (max-width: 980px) {
          font-size: 15px;
          padding-bottom: 15px;
        }
      }

      p {
        font-weight: 300;
        font-size: 25px;

        @media (max-width: 980px) {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
