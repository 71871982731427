import { createRouter, createWebHistory } from 'vue-router'

import Blank from '@/components/Blank'

import Index from '@/components/Index'

import Village from '@/components/pages/Village'
import Goods from '@/components/pages/Goods'
import ThanksTo from '@/components/pages/ThanksTo'
import Search from '@/components/pages/Search'

import DesignerList from '@/components/designers/List'
import DesignerInfo from '@/components/designers/Info'

import ProjectListGraphic from '@/components/projects/ListGraphic'
import ProjectListBranding from '@/components/projects/ListBranding'
import ProjectListBusiness from '@/components/projects/ListBusiness'
import ProjectListUx from '@/components/projects/ListUx'
import ProjectInfo from '@/components/projects/Info'

import Guestbook from '@/components/guestbook/Guestbook'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      layout: 'default',
      index: true
    }
  },

  {
    path: '/village',
    name: 'Village',
    component: Village,
    meta: {
      layout: 'default',
      menu: 'about'
    }
  },

  {
    path: '/goods',
    name: 'Goods',
    component: Goods,
    meta: {
      layout: 'default',
      menu: 'about'
    }
  },
  {
    path: '/thanks',
    name: 'ThanksTo',
    component: ThanksTo,
    meta: {
      layout: 'default',
      menu: 'participants'
    }
  },

  {
    path: '/designers',
    name: 'DesignerList',
    component: DesignerList,
    meta: {
      layout: 'default',
      menu: 'participants'
    }
  },
  {
    path: '/designer/:id',
    name: 'DesignerInfo',
    component: DesignerInfo,
    meta: {
      layout: 'default',
      menu: 'participants'
    }
  },

  {
    path: '/projects/graphic',
    name: 'ProjectListGraphic',
    component: ProjectListGraphic,
    meta: {
      layout: 'default',
      menu: 'projects'
    }
  },
  {
    path: '/projects/branding',
    name: 'ProjectListBranding',
    component: ProjectListBranding,
    meta: {
      layout: 'default',
      menu: 'projects'
    }
  },
  {
    path: '/projects/business',
    name: 'ProjectListBusiness',
    component: ProjectListBusiness,
    meta: {
      layout: 'default',
      menu: 'projects'
    }
  },
  {
    path: '/projects/ux',
    name: 'ProjectListUx',
    component: ProjectListUx,
    meta: {
      layout: 'default',
      menu: 'projects'
    }
  },
  {
    path: '/project/:id',
    name: 'ProjectInfo',
    component: ProjectInfo,
    meta: {
      layout: 'default',
      menu: 'projects'
    }
  },

  {
    path: '/guestbook',
    name: 'Guestbook',
    component: Guestbook,
    meta: {
      layout: 'default',
      menu: 'guestbook'
    }
  },

  {
    path: '/search/:keyword',
    name: 'Search',
    component: Search,
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: Blank,
    meta: {
      layout: 'default'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
