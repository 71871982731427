<template>
  <div class="layout-footer"></div>
</template>

<script>
export default {
  name: 'LayoutFooter'
}
</script>

<style lang="scss" scoped>
.layout-footer {}
</style>
