<template>
  <div class="index">
    <div class="video web">
      <video :poster="`${staticUrl}/images/web_motion.jpg`"
             muted autoplay loop playsinline>
        <source :src="`${staticUrl}/images/web_motion.mp4`"
                type="video/mp4">
        <strong>Your browser does not support the video tag.</strong>
      </video>
    </div>
    <div class="video mobile">
      <video :poster="`${staticUrl}/images/mobile_motion.jpg`"
             muted autoplay loop playsinline>
        <source :src="`${staticUrl}/images/mobile_motion.mp4`"
                type="video/mp4">
        <strong>Your browser does not support the video tag.</strong>
      </video>
    </div>
  </div>
</template>

<script>
import { staticUrl } from '@/utils/tools'

export default {
  name: 'IndexPage',

  methods: {

  },

  computed: {
    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  position: relative;
  height: calc(100vh - 90px);

  @media (max-width: 980px) {
    height: calc(100vh - 50px);
  }

  .video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    &.web {
      display: block;
      width: 100vw;
      height: 58.984375vw;

      @media (max-aspect-ratio: 2560/1600) {
        width: 169.536423841vh;
        height: 100vh;
      }

      @media (max-aspect-ratio: 1528 / 1180) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      width: 100vw;
      height: 130vw;

      @media (max-aspect-ratio: 800/1090) {
        width: 76.9230769231100vh;
        height: 100vh;
      }

      @media (max-aspect-ratio: 1528 / 1180) {
        display: block;
      }
    }

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
