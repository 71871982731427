<template>
  <div class="goods">
    <div class="list-header">
      <h2>Goods</h2>
    </div>

    <div class="list-body">
      <ul>
        <li>
          <div class="li-wrap">
            <div class="meta">
              <span class="name">떡메모지</span>
              <span class="price">1,500원</span>
            </div>
            <img :src="`${staticUrl}/images/goods/1.jpg`" alt="" />
          </div>
        </li>
        <li>
          <div class="li-wrap">
            <div class="meta">
              <span class="name">아크릴 키링 </span>
              <span class="price">8,000원</span>
            </div>
            <img :src="`${staticUrl}/images/goods/2.jpg`" alt="" />
          </div>
        </li>
        <li>
          <div class="li-wrap">
            <div class="meta">
              <span class="name">자유형스티커</span>
              <span class="price">2,000원</span>
            </div>
            <img :src="`${staticUrl}/images/goods/3.jpg`" alt="" />
          </div>
        </li>
        <li>
          <div class="li-wrap">
            <div class="meta">
              <span class="name">엽서</span>
              <span class="price">1,000원</span>
            </div>
            <img :src="`${staticUrl}/images/goods/4.jpg`" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { staticUrl } from '@/utils/tools'

export default {
  name: 'GoodsPage',

  computed: {
    staticUrl () {
      return staticUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.goods {
  max-width: 1612px;
  margin: 0 auto;

  .list-header {
    padding: 180px 0 200px;

    @media (max-width: 980px) {
      padding: 70px 0 100px;
    }

    h2 {
      font-size: 50px;
      font-weight: 800;
      text-align: center;

      @media (max-width: 980px) {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  .list-body {
    padding: 0 45px;

    @media (max-width: 980px) {
      padding: 0 12px;
    }

    ul {
      padding-bottom: 150px;

      &::after {
        clear: both;
        display: block;
        content: "";
      }

      li {
        float: left;
        width: 50%;

        @media (max-width: 980px) {
          width: 100%;
        }

        .li-wrap {
          padding: 0 15px 80px;

          @media (max-width: 980px) {
            padding: 0 8px 30px;
          }
        }

        .meta {
          font-weight: 400;
          color: #FFFFFF;
          font-size: 22px;
          padding: 19px 22px;
          background: #000000;

          @media (max-width: 600px) {
            font-size: 15px;
            padding: 8px 10px;
          }

          .price {
            float: right;
          }
        }

        img {
          width: 100%;
          margin-bottom: -3px;
        }
      }
    }
  }
}
</style>
