<template>
  <div class="search-page">
    <div class="search">
      <input id="searchMobile"
             type="text"
             placeholder="Search"
             @keyup.enter="search"
             v-model="keywordInput">
      <button @click="search">
        <img src="@/assets/icon--search-blk.svg"
             alt="검색" />
      </button>
    </div>

    <div v-if="result"
         class="result">
      <h2>
        <img src="@/assets/search-result.svg" alt="" />
        “{{ keyword }}” 검색 결과
      </h2>

      <div class="loading"
           v-if="result.count.designer + result.count.project < 1">
        <img src="@/assets/not-found.svg"
             alt="" />
        <p>
          <b>학생명 혹은 작품명</b>을 다시 입력해주세요
        </p>
      </div>

      <div v-if="result.count.designer > 0"
           class="designer-list">
        <h3>
          Designer
        </h3>

        <ul>
          <li v-for="designer of result.data.designer"
              :key="designer.id">
            <a :href="`/designer/${designer.id}`"
               @click.prevent="moving(`/designer/${designer.id}`, $event)">
              <div class="profile"
                   :style="{ backgroundImage: `url('${staticUrl}/images/designer/${designer.id}.jpg')` }">
                <img src="@/assets/profile.png" alt="" />
              </div>
              <div class="name">
              <span class="kor">
                {{ designer.nameKor }}
              </span>
                <span class="eng">
                {{ designer.nameEng }}
              </span>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="result.count.project > 0"
           class="project-list">
        <h3>
          Project
        </h3>

        <ul>
          <li v-for="project of result.data.project"
              :key="project.id">
            <a :href="`/project/${project.id}`"
               @click.prevent="moving(`/project/${project.id}`, $event)">
              <div class="profile">
                <img :src="`${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType}`"
                     :srcset="project.thumbType !== 'svg' ? `${staticUrl}/images/projects/${project.id}/thumb@2x.${project.thumbType} 2x` : undefined"
                     :onerror="project.thumbType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType} 2x';this.onerror=''` : undefined"
                     alt="" />
                <img class="blank"
                     src="@/assets/thumb.png"
                     alt="" />

                <div class="meta">
                  <p class="title">
                    {{ project.title }}
                  </p>
                  <span></span>
                  <p class="designers">
                    {{ project.Designers.map(designer => designer.nameKor).join(', ') }}
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { getSearch } from '@/api/projects'
import { staticUrl } from '@/utils/tools'

export default {
  name: 'SearchPage',

  created () {
    this.loadData()
  },

  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),

    loadData () {
      this.setLoading(true)
      getSearch({ keyword: this.keyword }).then(response => {
        this.result = response.data
        this.setLoading(false)
      }).catch(error => {
        console.error(error)
        this.setLoading(false)
      })
    },

    moving (href, e) {
      if (e.pointerType !== 'mouse') {
        setTimeout(() => {
          this.$router.push({ path: href })
        }, 1000)
      } else {
        this.$router.push({ path: href })
      }
    },

    search () {
      if (this.keywordInput) {
        this.$router.push({ name: this.$route.name, params: { keyword: this.keywordInput } })
      } else {
        this.$router.push({ path: '/' })
      }
    }
  },

  computed: {
    keyword () {
      return this.$route.params.keyword || ''
    },
    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {
      result: null,
      keywordInput: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.search-page {
  max-width: 1522px;
  margin: 0 auto;
  padding: 0 45px 100px;

  @media (max-width: 980px) {
    padding: 0 12px;
  }

  .loading {
    padding: calc(50vh - 400px) 60px;
    text-align: center;

    @media (max-width: 980px) {
      padding: calc(50vh - 300px) 120px;
    }

    p {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 300;
      text-transform: uppercase;
      color: #000000;
      line-height: 1.3;

      @media (max-width: 980px) {
        font-size: 18px;
      }
    }

    img {
      @media (max-width: 980px) {
        width: 135px;
      }
    }
  }

  .search {
    position: relative;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1492px;
    margin: 0 auto;
    display: none;

    @media (max-width: 980px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: 1280px) {
      display: block;
    }

    input {
      -webkit-appearance: none;
      border: 1px solid #707070;
      background: #FFFFFF;
      color: #000000;
      line-height: 37px;
      height: 37px;
      border-radius: 100px;
      width: calc(100% - 40px);
      padding: 0 20px;
      font-size: 15px;
      font-weight: 400;

      &::placeholder {
        color: #939393;
        font-size: 15px;
        font-weight: 500;
      }
    }

    button {
      position: absolute;
      top: 38px;
      right: 35px;

      img {
        width: 23px;
        height: 22px;
      }

      @media (max-width: 980px) {
        right: 30px;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    padding: 120px 30px 60px;

    @media (max-width: 980px) {
      padding: 45px 15px 30px;
      font-size: 14px;
    }

    img {
      vertical-align: top;
      position: relative;
      top: -10px;
      left: -10px;
      margin-right: 10px;

      @media (max-width: 980px) {
        width: 27px;
        top: -4px;
        left: -5px;
        margin-right: 2px;
      }
    }
  }

  .designer-list {
    h3 {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 30px;
      padding: 0 30px 0 20px;

      @media (max-width: 980px) {
        padding: 0 7px;
        font-size: 15px;
      }
    }

    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 25%;

        @media (max-width: 1280px) {
          width: 33.333333%;
        }

        @media (max-width: 800px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 0 15px 110px;
          transition: transform 0.15s cubic-bezier(0.65, 0, 0.35, 1);

          @media (max-width: 980px) {
            padding: 0 7px 45px;
          }

          &:hover {
            transform: scale(1.08);
            transform-origin: center center;

            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }
            }

            .name {
              padding: 20px 0 20px 25px;
              background-color: #FFF16E;

              @media (max-width: 800px) {
                padding: 15px 0 15px 20px;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              width: 100%;
              height: auto;
            }

            &::before {
              content: "";
              position: absolute;
              top: -85px;
              left: -85px;
              width: 85px;
              height: 85px;
              background: url('@/assets/profile-top.svg');
              background-size: 100% 100%;
              transition: top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              left 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                top: -46px;
                left: -46px;
                width: 46px;
                height: 46px;
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -80px;
              right: -80px;
              width: 80px;
              height: 80px;
              background: url('@/assets/profile-bottom.svg');
              background-size: 100% 100%;
              transition: bottom 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              right 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                bottom: -44px;
                right: -44px;
                width: 44px;
                height: 44px;
              }
            }
          }

          .name {
            padding: 20px 0;
            font-size: 1.3vw;
            transition: padding 0.15s cubic-bezier(0.65, 0, 0.35, 1),
            background-color 0.15s ease;

            @media (min-width: 1920px) {
              font-size: 25px;
            }

            @media (max-width: 1280px) {
              font-size: 1.95vw;
            }

            @media (max-width: 800px) {
              padding: 15px 0;
            }


            .kor {
              white-space: nowrap;
              font-weight: 500;
              height: 1em;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 800px) {
                display: block;
                font-size: 18px;
              }
            }

            .eng {
              white-space: nowrap;
              padding-left: 1.146vw;
              font-weight: 200;
              height: 1em;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 800px) {
                display: block;
                font-size: 15px;
                padding-top: 5px;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .project-list {
    h3 {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 30px;
      padding: 20px 30px 0 20px;

      @media (max-width: 980px) {
        padding: 0 7px;
        font-size: 15px;
      }
    }

    ul {
      padding-bottom: 100px;

      @media (max-width: 980px) {
        width: 100%;
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 25%;

        @media (max-width: 1280px) {
          width: 33.333333%;
        }

        @media (max-width: 800px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 0 15px 30px;
          position: relative;

          @media (max-width: 980px) {
            padding: 0 7px 15px;
          }

          &:hover {
            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }

              .meta {
                opacity: 1;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;

              &.blank {
                position: relative;
              }
            }

            .meta {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              opacity: 0;
              transition: opacity 0.15s ease;
              color: #FFFFFF;
              overflow: hidden;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 30px);
                height: 1.5px;
                background-color: #FFFFFF;
                opacity: 0.5;
              }

              .title {
                position: absolute;
                bottom: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 600;
                text-align: center;

                @media (max-width: 980px) {
                  font-size: 15px;
                  padding-bottom: 10px;
                }
              }

              .designers {
                position: absolute;
                top: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-top: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 300;
                text-align: center;

                @media (max-width: 980px) {
                  font-size: 14px;
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
