<template>
  <div class="designer-list">
    <div class="list-header">
      <h2>Designers</h2>
    </div>

    <div class="list-body">
      <ul>
        <li v-for="designer of list"
            :key="designer.id">
          <a :href="`/designer/${designer.id}`"
             @click.prevent="moving(`/designer/${designer.id}`, $event)">
            <div class="profile"
                 :style="{ backgroundImage: `url('${staticUrl}/images/designer/${designer.id}.jpg')` }">
              <img src="@/assets/profile.png" alt="" />
            </div>
            <div class="name">
              <span class="kor">
                {{ designer.nameKor }}
              </span>
              <span class="eng">
                {{ designer.nameEng }}
              </span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getDesignerList } from '@/api/designers'
import { staticUrl } from '@/utils/tools'

export default {
  name: 'DesignerList',

  created () {
    this.loadData()
  },

  methods: {
    loadData () {
      getDesignerList({})
        .then(response => {
          this.list = response.data.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    moving (href, e) {
      if (e.pointerType !== 'mouse') {
        setTimeout(() => {
          this.$router.push({ path: href })
        }, 1000)
      } else {
        this.$router.push({ path: href })
      }
    }
  },

  computed: {
    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {
      list: []
    }
  }
}
</script>

<style lang="scss" scoped>
.designer-list {
  max-width: 1612px;
  margin: 0 auto;

  .list-header {
    padding: 180px 0 200px;

    @media (max-width: 980px) {
      padding: 70px 0 100px;
    }

    h2 {
      font-size: 50px;
      font-weight: 800;
      text-align: center;

      @media (max-width: 980px) {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  .list-body {
    padding: 0 45px;

    @media (max-width: 980px) {
      padding: 0 12px;
    }

    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 25%;

        @media (max-width: 1280px) {
          width: 33.333333%;
        }

        @media (max-width: 800px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 0 15px 110px;
          transition: transform 0.15s cubic-bezier(0.65, 0, 0.35, 1);

          @media (max-width: 980px) {
            padding: 0 7px 45px;
          }

          &:hover {
            transform: scale(1.08);
            transform-origin: center center;

            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }
            }

            .name {
              padding: 20px 0 20px 25px;
              background-color: #FFF16E;

              @media (max-width: 800px) {
                padding: 15px 0 15px 20px;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              width: 100%;
              height: auto;
            }

            &::before {
              content: "";
              position: absolute;
              top: -85px;
              left: -85px;
              width: 85px;
              height: 85px;
              background: url('@/assets/profile-top.svg');
              background-size: 100% 100%;
              transition: top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
                          left 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                top: -46px;
                left: -46px;
                width: 46px;
                height: 46px;
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -80px;
              right: -80px;
              width: 80px;
              height: 80px;
              background: url('@/assets/profile-bottom.svg');
              background-size: 100% 100%;
              transition: bottom 0.3s cubic-bezier(0.65, 0, 0.35, 1),
                          right 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                bottom: -44px;
                right: -44px;
                width: 44px;
                height: 44px;
              }
            }
          }

          .name {
            padding: 20px 0;
            font-size: 1.3vw;
            transition: padding 0.15s cubic-bezier(0.65, 0, 0.35, 1),
                        background-color 0.15s ease;

            @media (min-width: 1920px) {
              font-size: 25px;
            }

            @media (max-width: 1280px) {
              font-size: 1.95vw;
            }

            @media (max-width: 800px) {
              padding: 15px 0;
            }


            .kor {
              white-space: nowrap;
              font-weight: 500;
              height: 1em;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 800px) {
                display: block;
                font-size: 18px;
              }
            }

            .eng {
              white-space: nowrap;
              padding-left: 1.146vw;
              font-weight: 200;
              height: 1em;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 800px) {
                display: block;
                font-size: 15px;
                padding-top: 5px;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
