<template>
  <div class="project-list">
    <div class="list-header">
      <h2>
        <img src="@/assets/project-business.svg"
             alt="" />
        <router-link class="graphic"
                     to="/projects/graphic">
          Graphic Design
        </router-link>
        <router-link class="branding"
                     to="/projects/branding">
          Branding & Identity
        </router-link>
        <router-link class="business"
                     to="/projects/business">
          Design Business
        </router-link>
        <router-link class="ux"
                     to="/projects/ux">
          UX/UI
        </router-link>
      </h2>
    </div>

    <div class="list-body">
      <div class="list-description"
           :class="{ gnbHidden }">
        <h2>
          Design Business
        </h2>
        <p>
          성공적인 비즈니스 혁신을 위한 디자인의 역할에 대한 이해와 함께 디자인적 사고와 방법론을 학습하여 디자인적 사고를 통한 가치 창출 및 전달 뿐만 아니라 수익 전환을 통한 지속가능한 비즈니스 아이디어를 구상하여 리를 비즈니스 모델 설계 및 비즈니스 실행을 위한 초기 창업 과정에 적용합니다. 디자인 기획, 디자인 마케팅, 디자인 비즈니스 등의 분야로 진출하고자 하는 학생들이 전시 작품을 선보입니다.
        </p>
      </div>
      <ul>
        <li v-for="project of list"
            :key="project.id">
          <a :href="`/project/${project.id}`"
             @click.prevent="moving(`/project/${project.id}`, $event)">
            <div class="profile">
              <img :src="`${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType}`"
                   :srcset="project.thumbType !== 'svg' ? `${staticUrl}/images/projects/${project.id}/thumb@2x.${project.thumbType} 2x` : undefined"
                   :onerror="project.thumbType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType} 2x';this.onerror=''` : undefined"
                   alt="" />
              <img class="blank"
                   src="@/assets/thumb.png"
                   alt="" />

              <div class="meta">
                <p class="title">
                  {{ project.title }}
                </p>
                <span></span>
                <p class="designers">
                  {{ project.Designers.map(designer => designer.nameKor).join(', ') }}
                </p>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getProjectList } from '@/api/projects'
import { staticUrl } from '@/utils/tools'

export default {
  name: 'ProjectListBusiness',

  created () {
    this.loadData()
  },

  methods: {
    loadData () {
      getProjectList({ category: 'business' })
        .then(response => {
          this.list = response.data.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    moving (href, e) {
      if (e.pointerType !== 'mouse') {
        setTimeout(() => {
          this.$router.push({ path: href })
        }, 1000)
      } else {
        this.$router.push({ path: href })
      }
    }
  },

  computed: {
    ...mapState({
      gnbHidden: 'gnbHidden'
    }),

    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {
      list: []
    }
  }
}
</script>

<style lang="scss" scoped>
.project-list {
  max-width: 1612px;
  margin: 0 auto;

  .list-header {
    padding: 70px 0 100px;

    @media (max-width: 980px) {
      padding: 20px 0;
    }

    h2 {
      text-align: center;
      position: relative;
      display: block;
      margin: 0 auto;
      max-width: 850px;

      a {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 40%;
        opacity: 0;

        &.graphic {
          top: 5%;
          left: 5%;
          width: 39%;
        }

        &.branding {
          top: 5%;
          left: 44%;
          width: 40%;
        }

        &.business {
          top: 45%;
          left: 17%;
          width: 44%;
        }

        &.ux {
          top: 45%;
          left: 61%;
          width: 36%;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .list-body {
    padding: 0 45px;

    @media (max-width: 980px) {
      padding: 0 12px;
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .list-description {
      position: sticky;
      top: 150px;
      float: left;
      width: 25%;
      transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

      &.gnbHidden {
        top: 60px;
      }

      @media (max-width: 980px) {
        position: static;
        width: 100%;
      }

      h2 {
        font-weight: 600;
        font-size: 35px;
        padding-bottom: 30px;
        padding-left: 15px;

        @media (max-width: 1280px) {
          font-size: 20px;
        }

        @media (max-width: 980px) {
          padding-left: 5px;
          padding-bottom: 15px;
        }
      }

      p {
        font-weight: 300;
        font-size: 20px;
        line-height: 1.5;
        padding-right: 15px;
        padding-left: 15px;

        @media (max-width: 1280px) {
          font-size: 15px;
        }

        @media (max-width: 980px) {
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 105px;
        }
      }
    }

    ul {
      float: left;
      width: 75%;
      padding-bottom: 100px;

      @media (max-width: 980px) {
        width: 100%;
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 33.333333%;

        @media (max-width: 1280px) {
          width: 50%;
        }

        @media (max-width: 980px) {
          width: 33.333333%;
        }

        @media (max-width: 600px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 0 15px 30px;
          position: relative;

          @media (max-width: 980px) {
            padding: 0 7px 15px;
          }

          &:hover {
            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }

              .meta {
                opacity: 1;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;

              &.blank {
                position: relative;
              }
            }

            .meta {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              opacity: 0;
              transition: opacity 0.15s ease;
              color: #FFFFFF;
              overflow: hidden;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 30px);
                height: 1.5px;
                background-color: #FFFFFF;
                opacity: 0.5;
              }

              .title {
                position: absolute;
                bottom: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 600;
                text-align: center;

                @media (max-width: 980px) {
                  font-size: 15px;
                  padding-bottom: 10px;
                }
              }

              .designers {
                position: absolute;
                top: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-top: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 300;
                text-align: center;

                @media (max-width: 980px) {
                  font-size: 14px;
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
