<template>
  <div class="project-info">
    <template v-if="project">
      <div class="info-main">
        <div class="main"
             :class="{ active: scrollTop > 100 }">
          <img class="web"
               :src="`${staticUrl}/images/projects/${project.id}/main_web.${project.mainWebType}`"
               :srcset="project.mainWebType !== 'svg' ? `${staticUrl}/images/projects/${project.id}/main_web@2x.${project.mainWebType} 2x` : undefined"
               :onerror="project.mainWebType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/main_web.${project.mainWebType} 2x';this.onerror=''` : undefined"
               alt="" />
          <img class="mobile"
               :src="`${staticUrl}/images/projects/${project.id}/main_mobile.${project.mainMobileType}`"
               :srcset="project.mainMobileType !== 'svg' ? `${staticUrl}/images/projects/${project.id}/main_mobile@2x.${project.mainMobileType} 2x` : undefined"
               :onerror="project.mainMobileType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/main_mobile.${project.mainMobileType} 2x';this.onerror=''` : undefined"
               alt="" />
          <div class="info">
            <div class="info-wrap">
              <h2>
                {{ project.title }}
              </h2>
              <div class="meta">
                <p class="designers">
                  <span v-for="designer in project.Designers"
                        :key="designer.id">
                    {{ designer.nameKor }} &nbsp; {{ designer.nameEng }}
                  </span>
                </p>
                <p class="description"
                    v-html="project.description"></p>
              </div>
            </div>
          </div>
          <Transition>
            <img v-show="showScroll"
                 class="scroll"
                 src="@/assets/project-scroll.svg"
                 alt="">
          </Transition>
        </div>
      </div>

      <div v-if="details"
           class="info-body">
        <template v-for="detail in details">
          <template v-if="detail.type === 'image'">
            <a v-if="detail.link"
               :key="detail.url"
               :href="detail.link"
               target="_blank">
              <img :src="`${staticUrl}/images/projects/${project.id}/${detail.url}.${detail.ext}`"
                   :srcset="detail.ext !== 'svg' ? `${staticUrl}/images/projects/${project.id}/${detail.url}@2x.${detail.ext} 2x` : undefined"
                   :onerror="detail.ext !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/${detail.url}.${detail.ext} 2x';this.onerror=''` : undefined"
                   alt="" />
            </a>
            <img v-else
                 :key="detail.url"
                 :src="`${staticUrl}/images/projects/${project.id}/${detail.url}.${detail.ext}`"
                 :srcset="detail.ext !== 'svg' ? `${staticUrl}/images/projects/${project.id}/${detail.url}@2x.${detail.ext} 2x` : undefined"
                 :onerror="detail.ext !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/${detail.url}.${detail.ext} 2x';this.onerror=''` : undefined"
                 alt="" />
          </template>
          <div v-else-if="detail.type === 'iframe'"
               :key="detail.url"
               class="iframe">
            <div class="iframe-wrap">
              <iframe :src="detail.url"></iframe>
            </div>
          </div>
        </template>
      </div>

      <div class="info-footer">
        <div class="info-footer-wrap">
          <div v-if="project.pdf"
               class="pdf">
            <h4>해당 프로젝트에 첨부된 파일을 확인해 보세요.</h4>
            <a :href="`https://docs.google.com/gview?url=${staticUrl}/images/projects/${project.id}/document.pdf`"
               target="_blank">
              <img class="web"
                   src="@/assets/project-pdf.svg"
                   alt="View PDF" />
              <img class="mobile"
                   src="@/assets/project-pdf-mobile.svg"
                   alt="View PDF" />
            </a>
          </div>

          <div v-if="project.goods && project.goods.length > 0"
               class="goods">
            <h4>해당 프로젝트와 연계된 굿즈작품을 소개합니다.</h4>

            <ul class="goods-list">
              <li v-for="goods of project.goods"
                  :key="goods.id">
                <a :href="`/project/${goods.id}`"
                   @click.prevent="moving(`/project/${goods.id}`, $event)">
                  <div class="profile">
                    <img :src="`${staticUrl}/images/projects/${goods.id}/thumb.${goods.thumbType}`"
                         :srcset="goods.thumbType !== 'svg' ? `${staticUrl}/images/projects/${goods.id}/thumb@2x.${goods.thumbType} 2x` : undefined"
                         :onerror="goods.thumbType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${goods.id}/thumb.${goods.thumbType} 2x';this.onerror=''` : undefined"
                         alt="" />
                    <img class="blank"
                         src="@/assets/thumb.png"
                         alt="" />

                    <div class="meta">
                      <p class="title">
                        {{ goods.title }}
                      </p>
                      <span></span>
                      <p class="designers">
                        {{ goods.Designers.map(designer => designer.nameKor).join(', ') }}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <ul class="designer-list">
            <li v-for="designer of project.Designers"
                :key="designer.id">
              <a :href="`/designer/${designer.id}`"
                 @click.prevent="moving(`/designer/${designer.id}`, $event)">
                <div class="profile"
                     :style="{ backgroundImage: `url('${staticUrl}/images/designer/${designer.id}.jpg')` }">
                  <img src="@/assets/profile.png" alt="" />
                </div>
                <div class="name">
                <span class="kor">
                  {{ designer.nameKor }}
                </span>
                  <span class="eng">
                  {{ designer.nameEng }}
                </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getProjectInfo } from '@/api/projects'
import { staticUrl } from '@/utils/tools'

export default {
  name: 'ProjectInfo',

  created () {
    this.loadData()
    window.addEventListener('scroll', this.handleScroll)
  },

  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapMutations({
      setGnbHidden: 'setGnbHidden'
    }),

    loadData () {
      getProjectInfo({ id: this.$route.params.id })
          .then(response => {
            this.setGnbHidden(true)

            this.project = response.data.data

            this.details = []
            for (const detail of this.project.content.split('|')) {
              if (detail) {
                const isExternalUrl = detail.substr(0, 4) === 'http'
                const urlSplited = detail.split(',')
                this.details.push({
                  type: isExternalUrl ? 'iframe' : 'image',
                  url: isExternalUrl ? detail : urlSplited[0].substr(0, urlSplited[0].length - 4),
                  ext: urlSplited[0].substr(-3, 3),
                  link: urlSplited.length > 1 ? urlSplited[1] : null
                })
              }
            }

            setTimeout(() => {
              this.showScroll = true
            }, 2000)
          })
          .catch(error => {
            console.error(error)
          })
    },

    handleScroll () {
      this.scrollTop = window.scrollY
      if (this.scrollTop < 1) {
        this.setGnbHidden(true)
      }
    },

    moving (href, e) {
      if (e.pointerType !== 'mouse') {
        setTimeout(() => {
          this.$router.push({ path: href })
        }, 1000)
      } else {
        this.$router.push({ path: href })
      }
    }
  },

  computed: {
    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {
      project: null,
      details: null,
      showScroll: false,
      scrollTop: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.project-info {
  margin-top: -90px;

  @media (max-width: 980px) {
   margin-top: -50px;
  }
  .info-main {
    position: relative;
    height: calc(150vh - 90px);

    @media (max-width: 980px) {
      height: calc(200vh - 50px);
    }

    .main {
      position: sticky;
      width: 100%;
      top: 0;
      height: 100vh;
      background: #999999;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        &.web {
          height: 100%;
          display: block;

          @media (max-aspect-ratio: 2/3) {
            display: none;
          }

          @media (min-aspect-ratio: 1920/1090) {
            width: 100%;
            height: auto;
          }
        }

        &.mobile {
          width: 100%;
          display: none;

          @media (max-aspect-ratio: 2/3) {
            display: block;
          }

          @media (max-aspect-ratio: 414/892) {
            width: auto;
            height: 100%;
          }
        }
      }

      &.active {
        .info {
          opacity: 1;
        }
      }

      .info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 0;
        transition: opacity 0.5s ease;
        color: #FFFFFF;
        overflow: hidden;
        transform: translateZ(0);

        .info-wrap {
          max-width: 1800px;
          padding: 0 60px;
          margin: 0 auto;

          @media (max-width: 980px) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          @media (max-width: 600px) {
            padding: 0 20px;
          }
        }

        h2 {
          font-weight: 600;
          font-size: 50px;
          text-align: center;
          margin: 17vh 0;
          line-height: 1.4;
          height: 70px;

          @media (max-width: 980px) {
            margin: 0 0 120px;
            font-size: 40px;
            height: auto;
          }

          @media (max-width: 600px) {
            font-size: 20px;
            height: auto;
          }
        }

        .meta {
          position: absolute;
          bottom: 130px;
          left: 50%;
          width: calc(100% - 120px);
          max-width: 1494px;
          padding: 0 60px;
          min-height: 35vh;
          transform: translateX(-50%);

          @media (max-width: 980px) {
            position: static;
            padding: 0;
            width: 100%;
            min-height: 0;
            transform: none;
          }
        }

        p {
          padding-top: 75px;
          line-height: 1.4;

          @media (max-width: 980px) {
            padding-top: 12px;
          }

          &.designers {
            float: left;
            width: 40%;
            font-size: 25px;

            span {
              display: block;
            }

            @media (max-width: 980px) {
              font-size: 15px;
              float: none;
              width: 100%
            }
          }

          &.description {
            float: right;
            width: 58%;
            font-size: 20px;
            line-height: 1.5em;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 8;
            //max-height: 12em;

            @media (max-width: 980px) {
              font-size: 15px;
              float: none;
              width: 100%;
              margin-bottom: 80px;
            }
          }
        }
      }

      .scroll {
        position: absolute;
        opacity: 1;
        top: auto;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%) translateZ(0);

        &.v-enter-active,
        &.v-leave-active {
          transition: opacity 0.5s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
          opacity: 0;
        }
      }
    }
  }

  .info-body {
    max-width: 1614px;
    margin: 0 auto;
    text-align: center;
    padding: 100px 60px 0;

    @media (max-width: 980px) {
      padding: 47px 0 0;
    }

    img {
      width: 100%;
      max-width: 1494px;
      margin-bottom: -3px;
    }

    .iframe {
      position: relative;
      margin: 60px 0 57px;
      max-width: 1494px;

      @media (max-width: 980px) {
        margin: 20px 0 17px;
      }

      .iframe-wrap {
        padding-top: 56.25%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .info-footer {
    max-width: 1612px;
    margin: 0 auto;

    .info-footer-wrap {
      padding: 0 42px;

      @media (max-width: 980px) {
        padding: 0 12px;
      }
    }

    .pdf {
      padding: 100px 18px 0;

      @media (max-width: 980px) {
        padding: 50px 7px 0;
      }

      h4 {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 35px;

        @media (max-width: 980px) {
          margin-bottom: 15px;
          font-size: 15px;
        }
      }

      img {
        &.web {
          display: block;

          @media (max-width: 980px) {
            display: none;
          }
        }

        &.mobile {
          width: 60%;
          max-width: 732px;
          display: none;

          @media (max-width: 980px) {
            display: block;
          }
        }
      }
    }

    .goods {
      padding: 100px 18px 0;

      @media (max-width: 980px) {
        padding: 50px 7px 0;
      }

      h4 {
        margin-bottom: 35px;
        font-size: 30px;
        font-weight: 500;

        @media (max-width: 980px) {
          margin-bottom: 15px;
          font-size: 15px;
        }
      }
    }

    ul.goods-list {
      li {
        max-width: 732px;

        a {
          display: block;
          position: relative;

          &:hover {
            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }

              .meta {
                opacity: 1;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;

              &.blank {
                position: relative;
              }
            }

            .meta {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              opacity: 0;
              transition: opacity 0.15s ease;
              color: #FFFFFF;
              overflow: hidden;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 30px);
                height: 1.5px;
                background-color: #FFFFFF;
                opacity: 0.5;
              }

              .title {
                position: absolute;
                bottom: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 600;
                text-align: center;

                @media (max-width: 600px) {
                  font-size: 15px;
                  padding-bottom: 10px;
                }
              }

              .designers {
                position: absolute;
                top: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-top: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 300;
                text-align: center;

                @media (max-width: 600px) {
                  font-size: 14px;
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    ul.designer-list {
      margin-top: 200px;

      @media (max-width: 980px) {
        margin-top: 100px;
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 25%;

        @media (max-width: 1280px) {
          width: 33.333333%;
        }

        @media (max-width: 800px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 0 15px 110px;

          @media (max-width: 980px) {
            padding: 0 7px 45px;
          }

          &:hover {
            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              width: 100%;
              height: auto;
            }

            &::before {
              content: "";
              position: absolute;
              top: -85px;
              left: -85px;
              width: 85px;
              height: 85px;
              background: url('@/assets/profile-top.svg');
              background-size: 100% 100%;
              transition: top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              left 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                top: -46px;
                left: -46px;
                width: 46px;
                height: 46px;
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -80px;
              right: -80px;
              width: 80px;
              height: 80px;
              background: url('@/assets/profile-bottom.svg');
              background-size: 100% 100%;
              transition: bottom 0.3s cubic-bezier(0.65, 0, 0.35, 1),
              right 0.3s cubic-bezier(0.65, 0, 0.35, 1);

              @media (max-width: 800px) {
                bottom: -44px;
                right: -44px;
                width: 44px;
                height: 44px;
              }
            }
          }

          .name {
            padding: 20px 0;
            font-size: 1.3vw;

            @media (min-width: 1920px) {
              font-size: 25px;
            }

            @media (max-width: 1280px) {
              font-size: 1.95vw;
            }

            @media (max-width: 800px) {
              padding: 15px 0;
            }


            .kor {
              white-space: nowrap;
              font-weight: 500;

              @media (max-width: 800px) {
                display: block;
                font-size: 18px;
              }
            }

            .eng {
              white-space: nowrap;
              padding-left: 1.146vw;
              font-weight: 200;

              @media (max-width: 800px) {
                display: block;
                font-size: 15px;
                padding-top: 5px;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
