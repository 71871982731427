<template>
  <div class="guestbook">
    <div class="book-header">
      <img class="title web"
           src="@/assets/guestbook-title.svg"
           alt="Guest Book" />
      <img class="title mobile"
           src="@/assets/guestbook-title-mobile.svg"
           alt="Guest Book" />
      <img class="circle"
           src="@/assets/guestbook-circle.svg"
           alt="" />
    </div>

    <div class="book-body">
      <div class="book-input">
        <label class="to">
          <h5>To</h5>
          <div v-if="designers"
               class="dropdown"
               :class="{ active: showTo }">
            <div class="current"
                 @click="toggleTo">
              {{ currentTo }}
            </div>
            <Transition>
              <div v-if="showTo"
                   class="list">
                <ul>
                  <li :class="{ active: !to }"
                      @click="setTo({})">
                    KHVD(모두)
                  </li>
                  <li v-for="designer of designers"
                      :key="designer.id"
                      :class="{ active: to === designer.id }"
                      @click="setTo(designer)">
                    {{ designer.nameKor }}
                  </li>
                </ul>
              </div>
            </Transition>
          </div>
        </label>
        <label class="from">
          <h5>From</h5>
          <div class="input">
            <input type="text"
                   v-model="from" />
          </div>
        </label>

        <div class="content">
          <textarea placeholder="300자 이내로 작성해주세요"
                    v-model="content"></textarea>
          <button @click="send"
                  :disabled="!from || !content">
            Send
          </button>
        </div>
      </div>

      <div class="book-list">
        <div class="list-header">
          <div v-if="designers"
               class="dropdown"
               :class="{ active: showView }">
            <div class="current"
                 @click="toggleView">
              {{ currentView }}
            </div>
            <Transition>
              <div v-if="showView"
                   class="list">
                <ul>
                  <li :class="{ active: !view }"
                      @click="setView({})">
                    KHVD(모두)
                  </li>
                  <li v-for="designer of designers"
                      :key="designer.id"
                      :class="{ active: view === designer.id }"
                      @click="setView(designer)">
                    {{ designer.nameKor }}
                  </li>
                </ul>
              </div>
            </Transition>
          </div>
        </div>
        <div class="list-body">
          <ul v-if="list">
            <li v-for="item of list"
                :key="item.id">
              <div class="to">
                To {{ getName(item.to) }}
                <span class="date">
                  {{ item.createdAt.substr(0, 10).replaceAll('-', '.') }}
                </span>
              </div>
              <pre class="content">{{ item.content }}</pre>
              <div class="from">
                From &nbsp;{{ item.from }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { getDesignerList } from '@/api/designers'
import { getGuestbookList, createGuestbookInfo } from '@/api/guestbook'

export default {
  name: 'GuestBook',

  created () {
    this.loadData()
  },

  mounted () {
    document.body.style.backgroundColor = '#63A0F2'
  },

  unmounted () {
    document.body.style.backgroundColor = '#FFFFFF'
  },

  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),

    loadData () {
      this.setLoading(true)
      getDesignerList({})
          .then(response => {
            this.designers = []
            for (const designer of response.data.data) {
              if (designer.id === 10) {
                this.designers.push({
                  ...designer,
                  nameKor: designer.nameKor + '(19)'
                })
              } else if (designer.id === 11) {
                this.designers.push({
                  ...designer,
                  nameKor: designer.nameKor + '(17)'
                })
              } else {
                this.designers.push(designer)
              }
            }
            this.loadList()
            this.setDesigner()
          })
          .catch(error => {
            console.error(error)
            this.setLoading(false)
          })
    },

    loadList () {
      const params = {}
      if (this.view !== 0) {
        params.to = this.view
      }
      this.setLoading(true)
      getGuestbookList(params)
          .then(response => {
            this.list = response.data.data
            this.setLoading(false)
          })
          .catch(error => {
            console.error(error)
            this.setLoading(false)
          })
    },

    setDesigner () {
      if (this.$route.query.designer) {
        const found = this.designers.find(el => el.id === parseInt(this.$route.query.designer))
        if (found) {
          this.setTo(found, false)
        }
      }
    },

    getName (id) {
      if (!id || !this.designers) {
        return 'KHVD(모두)'
      }
      id = parseInt(id)
      const found = this.designers.find(el => {
        return el.id === id
      })
      return found ? found.nameKor : 'KHVD(모두)'
    },

    setTo (designer, showToggle = true) {
      this.to = designer.id || 0
      if (showToggle) {
        this.toggleTo()
      }
    },

    setView (designer) {
      this.view = designer.id || 0
      this.toggleView()
      this.loadList()
    },

    toggleTo () {
      this.showTo = !this.showTo
    },

    toggleView () {
      this.showView = !this.showView
    },

    send () {
      if (this.from && this.content) {
        this.setLoading(true)
        createGuestbookInfo({
          to: this.to,
          from: this.from,
          content: this.content
        }).then(() => {
          this.loadList()
          this.content = ''
        }).catch(error => {
          console.error(error)
          this.setLoading(false)
        })
      }
    }
  },

  watch: {
    from (value) {
      if (value.length > 12) {
        this.from = value.substr(0, 12)
      }
    },

    content (value) {
      if (value.length > 300) {
        this.content = value.substr(0, 300)
      }
    }
  },

  computed: {
    currentTo () {
      if (!this.to || !this.designers) {
        return 'KHVD(모두)'
      }
      const found = this.designers.find(el => el.id === this.to)
      return found ? found.nameKor : 'KHVD(모두)'
    },

    currentView () {
      if (!this.view || !this.designers) {
        return 'KHVD(모두)'
      }
      const found = this.designers.find(el => el.id === this.view)
      return found ? found.nameKor : 'KHVD(모두)'
    }
  },

  data () {
    return {
      to: 0,
      from: '',
      content: '',

      view: 0,

      show: 0,
      showTo: false,
      showView: false,

      designers: null,
      list: null
    }
  }
}
</script>

<style lang="scss" scoped>
.guestbook {
  max-width: 1612px;
  margin: 0 auto;
  padding: 105px 60px 0;
  box-sizing: border-box;

  @media (max-width: 980px) {
    padding: 25px 20px 0;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .book-header {
    float: left;
    width: calc(40% - 20px);
    max-width: 477px;
    text-align: center;
    position: sticky;
    top: 120px;
    padding-right: 20px;

    @media (max-width: 980px) {
      float: none;
      width: auto;
      max-width: 100%;
      position: relative;
      padding: 0;
      text-align: left;
      top: 0;
    }

    img {
      max-width: 100%;
      margin-bottom: 77px;

      @media (max-width: 980px) {
        margin-bottom: 0;
      }

      &.circle {
        position: relative;
        margin-left: 40px;
        max-width: calc(100% - 80px);
        z-index: 1;

        @media (max-width: 980px) {
          max-width: 100%;
          margin-left: 0;
          width: 32%;
        }
      }

      &.title {
        &.web {
          display: block;

          @media (max-width: 980px) {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media (max-width: 980px) {
            display: block;
            position: absolute;
            top: 27%;
            left: 0;
            z-index: 1;
            width: 100%;
          }
        }
      }
    }
  }

  .book-body {
    float: right;
    width: 60%;

    @media (max-width: 980px) {
      float: none;
      width: auto;
      margin-top: -2px;
    }

    .book-input {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

      .to {
        position: relative;
        float: left;
        width: 50%;
        height: 48px;
        display: block;
        background: url('@/assets/guestbook-to.svg') #FFFFFF no-repeat left center;
        background-size: auto 100%;

        @media (max-width: 1280px) {
          float: none;
          width: 100%;
        }

        @media (max-width: 980px) {
          height: 33px;
        }

        &::after {
          content: '';
          clear: both;
          display: block;
        }

        h5 {
          float: left;
          padding-left: 45px;
          width: 80px;
          border-right: 1.5px solid #DDDDDD;
          line-height: 26px;
          margin: 11px 0;
          font-weight: 400;
          font-size: 22px;

          @media (max-width: 980px) {
            padding-left: 30px;
            width: 60px;
            margin: 8px 0;
            line-height: 15px;
            font-size: 16px;
          }
        }

        .dropdown {
          float: right;
          width: calc(100% - 127px);
          cursor: pointer;
          position: relative;

          @media (max-width: 980px) {
            width: calc(100% - 93px);
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            width: 13px;
            height: 8px;
            background: url('@/assets/dropdown-black.svg') no-repeat;
            transform: translateY(-50%);
            transition: transform 0.5s ease;
          }

          &.active {
            &::after {
              transform: translateY(-50%) rotate(180deg);
            }
          }

          .current {
            font-weight: 400;
            font-size: 22px;
            color: #000;
            padding: 0 30px;
            line-height: 48px;

            @media (max-width: 980px) {
              line-height: 33px;
              font-size: 16px;
              padding: 0 10px;
            }
          }

          .list {
            position: absolute;
            z-index: 99;
            top: 48px;
            left: 0;
            width: 100%;
            height: 430px;
            background-color: #FFFFFF;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            overflow: scroll;
            transform-origin: top center;

            @media (max-width: 980px) {
              top: 33px;
            }

            &.v-enter-active,
            &.v-leave-active {
              transition: height 0.5s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
              height: 0;
            }

            ul {
              padding: 14px 0;

              li {
                text-align: center;
                font-size: 18px;
                text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                font-weight: 400;
                padding: 10px 0 9px;

                &.active {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .from {
        position: relative;
        float: left;
        display: block;
        width: 50%;
        height: 48px;
        background: url('@/assets/guestbook-from.svg') #FFFFFF no-repeat left center;
        background-size: auto 100%;

        @media (max-width: 1280px) {
          float: none;
          width: 100%;
        }

        @media (max-width: 980px) {
          height: 33px;
        }

        &::after {
          content: '';
          clear: both;
          display: block;
        }

        h5 {
          float: left;
          padding-left: 45px;
          width: 80px;
          border-right: 1.5px solid #DDDDDD;
          line-height: 26px;
          margin: 11px 0;
          font-weight: 400;
          font-size: 22px;

          @media (max-width: 980px) {
            padding-left: 30px;
            width: 60px;
            margin: 8px 0;
            line-height: 15px;
            font-size: 16px;
          }
        }

        .input {
          float: right;
          width: calc(100% - 127px);

          @media (max-width: 980px) {
            width: calc(100% - 93px);
          }

          input {
            -webkit-appearance: none;
            font-weight: 400;
            font-size: 22px;
            color: #000;
            padding: 0 30px;
            width: calc(100% - 60px);
            border: none;
            border-radius: 0;
            height: 48px;
            line-height: 48px;

            @media (max-width: 980px) {
              font-size: 16px;
              padding: 0 10px;
              line-height: 33px;
              height: 33px;
            }
          }
        }
      }

      .content {
        position: relative;
        clear: both;
        background: #F2F2F2;
        height: 310px;

        @media (max-width: 980px) {
          height: 280px;
        }

        textarea {
          width: calc(100% - 160px);
          height: calc(100% - 140px);
          resize: none;
          border: none;
          padding: 70px 80px;
          color: #000000;
          font-size: 22px;
          font-weight: 400;
          line-height: 35px;
          outline: none;
          background-color: transparent;
          background: url('@/assets/guestbook-content-top.svg') no-repeat right top,
                      url('@/assets/guestbook-content-bottom.svg') no-repeat left bottom;

          @media (max-width: 980px) {
            width: calc(100% - 100px);
            height: calc(100% - 100px);
            padding: 50px;
            font-size: 15px;
            line-height: 23px;
            background-size: 67px 71px;
          }

          &::placeholder {
            color: #7B7B7B;
          }
        }

        button {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 24px;
          font-weight: 500;
          background-color: #D8D8D8;
          padding: 17px 67px;
          border: 2.5px solid #D8D8D8;
          text-transform: uppercase;
          color: #898989;
          transition: border-color 0.15s ease,
                      background-color 0.15s ease,
                      color 0.15s ease;

          @media (max-width: 980px) {
            font-size: 15px;
            padding: 13px 40px;
            border: 1.5px solid #D8D8D8;
          }

          &:hover {
            background-color: #8AEFC3;
            border-color: #3FDE99;
            color: #000000;
          }

          &:active {
            background-color: #3FDE99;
            border-color: #3FDE99;
            color: #000000;
          }

          &:disabled {
            background-color: #F0F0F0;
            border-color: #C1C1C1;
            color: #898989;
            cursor: not-allowed;
          }
        }
      }
    }

    .book-list {
      @media (max-width: 980px) {
        margin: 55px -20px 0;
      }

      .list-header {
        background-color: #212121;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        padding: 28px 50px;

        @media (max-width: 980px) {
          padding: 25px 20px 23px;
        }
      }

      .dropdown {
        width: 222px;
        height: 30px;
        cursor: pointer;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 15px;
          width: 13px;
          height: 8px;
          background: url('@/assets/dropdown-yellow.svg') no-repeat;
          transform: translateY(-50%);
          transition: transform 0.5s ease;
        }

        &.active {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .current {
          font-weight: 300;
          font-size: 16px;
          color: #FFF16E;
          padding: 0 12px;
          line-height: 30px;
          border: 1px solid #626262;
        }

        .list {
          position: absolute;
          z-index: 99;
          top: 32px;
          left: 0;
          width: 100%;
          height: 222px;
          background-color: #212121;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          overflow: scroll;
          transform-origin: top center;

          &.v-enter-active,
          &.v-leave-active {
            transition: height 0.5s ease;
          }

          &.v-enter-from,
          &.v-leave-to {
            height: 0;
          }

          ul {
            padding: 14px 0;

            li {
              text-align: center;
              font-size: 18px;
              text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
              font-weight: 400;
              padding: 10px 0 9px;
              color: #FFFFFF;

              &.active {
                font-weight: 600;
              }
            }
          }
        }
      }

      .list-body {
        background: #181818;
        min-height: calc(100vh - 639px);
        color: #FFFFFF;

        ul {
          padding-bottom: 150px;

          li {
            background: url('@/assets/guestbook-item.svg') no-repeat right bottom;
            margin: 0 60px;
            border-bottom: 1.5px solid #666666;

            @media (max-width: 980px) {
              margin: 0 20px;
            }

            &::after {
              content: '';
              clear: both;
              display: block;
            }

            .to {
              font-size: 16px;
              font-weight: 400;
              color: #FFF16E;
              padding-top: 25px;

              .date {
                font-size: 12px;
                font-weight: 300;
                color: #898989;
                margin-left: 10px;
              }
            }

            .content {
              font-size: 15px;
              font-weight: 300;
              line-height: 23px;
              padding: 15px 0 25px;
              word-break: break-all;
              white-space: pre-wrap;

              @media (max-width: 980px) {
                font-size: 13px;
                font-weight: 100;
              }
            }

            .from {
              font-size: 16px;
              font-weight: 300;
              color: #3FDE99;
              padding-bottom: 10px;
              width: 212px;
              text-align: right;
              float: right;
              margin-right: 70px;
            }
          }
        }
      }
    }
  }
}
</style>
