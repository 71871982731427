import httpClient from '@/utils/http-client'

export function getProjectList (params) {
  const url = '/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function getProjectInfo (params) {
  const url = '/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function getSearch (params) {
  const url = '/s'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
