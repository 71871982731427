<template>
  <div class="designer-info">
    <template v-if="designer">
      <div class="info-profile">
        <div class="profile"
             :style="{ backgroundImage: `url('${staticUrl}/images/designer/${designer.id}.jpg')` }">
          <img src="@/assets/profile.png" alt="" />
        </div>

        <div class="meta">
          <div class="name">
            <span class="kor">
              {{ designer.nameKor }}
            </span>
            <span class="eng">
              {{ designer.nameEng }}
            </span>
          </div>

          <div class="contact">
            <p class="title">
              Contact
            </p>
            <p class="email">
              <a :href="`mailto:${designer.email}`">
                {{ designer.email }}
              </a>
            </p>
          </div>

          <div class="message">
            <img src="@/assets/profile-message.svg"
                 alt="한 줄 소감" />
            <p>
              <span class="marquee-content"
                    :style="{ animationDuration: designer.message.length * 4 + 's' }">
                <span>{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
                <span aria-hidden="true">{{ designer.message }}</span>
              </span>
              <span class="marquee-content"
                    aria-hidden="true"
                    :style="{ animationDuration: designer.message.length * 4 + 's' }">
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
                <span>{{ designer.message }}</span>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div v-if="designer.Projects && designer.Projects.length > 0"
           class="info-projects">
        <h4>Projects</h4>
        <ul>
          <li v-for="project of designer.Projects"
              :key="project.id">
            <a :href="`/project/${project.id}`"
               @click.prevent="moving(`/project/${project.id}`, $event)">
              <div class="profile">
                <img :src="`${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType}`"
                     :srcset="project.thumbType !== 'svg' ? `${staticUrl}/images/projects/${project.id}/thumb@2x.${project.thumbType} 2x` : undefined"
                     :onerror="project.thumbType !== 'svg' ? `this.srcset='${staticUrl}/images/projects/${project.id}/thumb.${project.thumbType} 2x';this.onerror=''` : undefined"
                     alt="" />
                <img class="blank"
                     src="@/assets/thumb.png"
                     alt="" />

                <div class="meta">
                  <p class="title">
                    {{ project.title }}
                  </p>
                  <span></span>
                  <p class="designers">
                    {{ project.Designers.map(designer => designer.nameKor).join(', ') }}
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { getDesignerInfo } from '@/api/designers'
import { staticUrl } from '@/utils/tools'

export default {
  name: 'DesignerInfo',

  created () {
    this.loadData();
  },

  methods: {
    loadData () {
      getDesignerInfo({ id: this.$route.params.id })
          .then(response => {
            this.designer = response.data.data
          })
          .catch(error => {
            console.error(error)
          })
    },

    moving (href, e) {
      if (e.pointerType !== 'mouse') {
        setTimeout(() => {
          this.$router.push({ path: href })
        }, 1000)
      } else {
        this.$router.push({ path: href })
      }
    }
  },

  computed: {
    staticUrl () {
      return staticUrl
    }
  },

  data () {
    return {
      designer: null
    }
  }
}
</script>

<style lang="scss" scoped>
.designer-info {
  max-width: 1612px;
  margin: 0 auto;
  padding-bottom: 277px;

  @media (max-width: 980px) {
    padding-bottom: 100px;
  }

  .info-profile {
    padding: 215px 60px 0;

    @media (max-width: 980px) {
      padding: 71px 20px 0;
    }

    .profile {
      display: inline-block;
      vertical-align: bottom;
      width: 478px;
      background-size: cover;

      @media (max-width: 980px) {
        width: 100%;
        max-width: 600px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .meta {
      display: inline-block;
      vertical-align: bottom;
      width: calc(100% - 478px);

      @media (max-width: 980px) {
        margin-top: 10px;
        width: 100%;
        padding-top: 20px;
      }

      .name {
        padding-left: 30px;

        @media (max-width: 980px) {
          padding-left: 0;
        }

        .kor {
          white-space: nowrap;
          font-size: 40px;
          font-weight: 500;
          padding-right: 25px;
          line-height: 40px;
          vertical-align: middle;

          @media (max-width: 600px) {
            font-size: 25px;
            line-height: 25px;
            padding-right: 20px;
          }
        }

        .eng {
          white-space: nowrap;
          font-size: 25px;
          font-weight: 200;
          line-height: 40px;
          vertical-align: middle;

          @media (max-width: 600px) {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }

      .contact {
        padding-top: 50px;
        padding-left: 30px;

        @media (max-width: 980px) {
          padding-left: 0;
        }

        @media (max-width: 600px) {
          padding-top: 30px;
        }

        .title {
          font-weight: 500;
          font-size: 20px;
          padding-bottom: 10px;
          text-transform: uppercase;

          @media (max-width: 600px) {
            font-size: 18px;
          }
        }

        .email {
          font-size: 20px;
          font-weight: 300;

          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
      }

      .message {
        position: relative;
        background-color: #FFF593;
        margin-left: 30px;
        margin-top: 70px;

        @media (max-width: 980px) {
          margin-left: 0;
        }

        @media (max-width: 600px) {
          margin-top: 60px;
        }

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 17px);
          left: 197px;
          width: 23px;
          height: 33px;
          background: url('@/assets/profile-message-top.svg');
          background-size: 100% 100%;
          z-index: 2;

          @media (max-width: 600px) {
            top: calc(50% - 11px);
            left: 15px;
            width: 15px;
            height: 22px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 35px;
          height: 35px;
          background: url('@/assets/profile-message-bottom.svg');
          background-size: 100% 100%;
          z-index: 2;

          @media (max-width: 600px) {
            width: 22px;
            height: 22px;
          }
        }

        img {
          position: absolute;
          top: -33px;
          left: 0;
          width: 185px;
          height: 126px;
          z-index: 2;

          @media (max-width: 600px) {
            width: 88px;
            height: 59px;
          }
        }

        p {
          overflow: hidden;
          line-height: 106px;
          color: #63A0F2;
          font-weight: 600;
          font-size: 25px;
          white-space: nowrap;
          margin-left: 230px;
          --gap: 1rem;
          display: flex;
          user-select: none;
          gap: 70px;

          @media (max-width: 600px) {
            line-height: 66px;
            font-size: 15px;
            margin-left: 30px;
          }

          .marquee-content {
            flex-shrink: 0;
            display: flex;
            justify-content: space-around;
            min-width: 100%;
            gap: 70px;
            animation-name: scroll;
            animation-duration: 60s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-100%);
            }
          }
        }
      }
    }
  }

  .info-projects {
    padding: 276px 45px 0;

    @media (max-width: 980px) {
      padding: 100px 15px 0;
    }

    h4 {
      margin-bottom: 35px;
      margin-left: 15px;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;

      @media (max-width: 980px) {
        margin-bottom: 15px;
        font-size: 20px;
      }
    }

    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      li {
        float: left;
        width: 50%;

        a {
          display: block;
          padding: 0 15px 30px;
          position: relative;

          @media (max-width: 980px) {
            padding: 0 7px 15px;
          }

          &:hover {
            .profile {
              &::before {
                top: 0;
                left: 0;
              }

              &::after {
                bottom: 0;
                right: 0;
              }

              .meta {
                opacity: 1;
              }
            }
          }

          .profile {
            position: relative;
            overflow: hidden;
            width: 100%;
            background-size: cover;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;

              &.blank {
                position: relative;
              }
            }

            .meta {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              opacity: 0;
              transition: opacity 0.15s ease;
              color: #FFFFFF;
              overflow: hidden;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 30px);
                height: 1.5px;
                background-color: #FFFFFF;
                opacity: 0.5;
              }

              .title {
                position: absolute;
                bottom: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 600;
                text-align: center;

                @media (max-width: 600px) {
                  font-size: 15px;
                  padding-bottom: 10px;
                }
              }

              .designers {
                position: absolute;
                top: 50%;
                left: 15px;
                width: calc(100% - 30px);
                padding-top: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 300;
                text-align: center;

                @media (max-width: 600px) {
                  font-size: 14px;
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
