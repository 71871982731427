import httpClient from '@/utils/http-client'

export function getGuestbookList (params) {
  const url = '/g/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function createGuestbookInfo (params) {
  const url = '/g/c'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
