<template>
  <div class="default_layout">
    <layout-header class="layout-header" />
    <div class="layout-content">
      <Transition>
        <div class="layout-content--wrap"
             :key="$route.fullPath">
          <router-view />
        </div>
      </Transition>
    </div>
    <layout-footer class="layout-footer" />
    <Transition>
      <div class="loading"
           v-if="loading">
        <img src="@/assets/loading.svg" alt="Loading" />
        <p>Loading{{ loadingDot }}</p>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Header from '@/components/_layout/Header'
import Footer from '@/components/_layout/Footer'
export default {
  name: 'DefaultLayout',
  components: {
    'layout-header': Header,
    'layout-footer': Footer
  },
  watch: {
    '$route.fullPath' () {
      window.scrollTo(0, 0)
    }
  },
  mounted () {
    this.loadingTimer = setInterval(this.nextLoadingDot, 500)
  },
  unmounted () {
    clearInterval(this.loadingTimer)
  },
  methods: {
    nextLoadingDot () {
      if (this.loadingDot === '...') {
        this.loadingDot = '.'
      } else {
        this.loadingDot += '.'
      }
    }
  },
  computed: {
    ...mapState({
      loading: 'loading'
    })
  },
  data () {
    return {
      loadingTimer: null,
      loadingDot: '...'
    }
  }
}
</script>

<style lang="scss" scoped>
.default_layout {
  position: relative;

  .layout-header {
    position: relative;
    z-index: 100;
  }

  .layout-content {
    position: relative;
    z-index: 3;

    .layout-content--wrap {
      &.v-enter-active,
      &.v-leave-active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity 0.5s ease;
      }

      &.v-enter-from,
      &.v-leave-to {
        opacity: 0;
      }
    }
  }

  .loading {
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &.v-enter-active,
    &.v-leave-active {
      transition: opacity 0.5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }

    p {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 500;
      text-transform: uppercase;
      width: 141px;
      text-align: left;
      color: #FFFFFF;
      text-shadow: 0 0 2px #000000;
    }
  }
}
</style>