<template>
  <div class="layout-header"
       @mouseleave="toggleSub(false)">
    <div class="header"
         :class="{ hide: !isIndex && gnbHidden && (scrollTop > 10 || $route.name === 'ProjectInfo'), expand: showSub }">
      <div class="gnb">
        <div class="gnb-wrap">
          <h1 class="logo">
            <router-link to="/">
              <img src="@/assets/logo.svg"
                   alt="VILLAGE" />
            </router-link>
          </h1>

          <div class="hamburger">
            <button @click="toggleMobileNav">
              <img src="@/assets/icon--hamburger.svg"
                   alt="메뉴 열기" />
            </button>
          </div>

          <div class="search"
               :class="{ active: showSearch || ($route.params.keyword && keyword), searching: $route.params.keyword }">
            <input id="search"
                   type="text"
                   placeholder="Search"
                   @focus="toggleSearch(true)"
                   @blur="toggleSearch(false)"
                   @keyup.enter="search"
                   v-model="keyword">
            <Transition>
              <button v-if="!showSearch"
                      class="mobile"
                      @click="search">
                <img src="@/assets/icon--search.svg"
                     alt="검색" />
              </button>
            </Transition>
            <button class="web"
                    @click="search">
              <img src="@/assets/icon--search.svg"
                   alt="검색" />
            </button>
          </div>

          <ul class="navigation"
              :class="[`active-${hover || $route.meta.menu}`]">
            <li :class="{ active: hover === 'about' || (!hover && $route.meta.menu === 'about') }"
                @mouseenter="hoverMenu('about')"
                @mouseleave="hoverMenu(null)"
                @click="toggleSub()">
              About
            </li>
            <li :class="{ active: hover === 'participants' || (!hover && $route.meta.menu === 'participants') }"
                @mouseenter="hoverMenu('participants')"
                @mouseleave="hoverMenu(null)"
                @click="toggleSub()">
              Participants
            </li>
            <li :class="{ active: hover === 'projects' || (!hover && $route.meta.menu === 'projects') }"
                @mouseenter="hoverMenu('projects')"
                @mouseleave="hoverMenu(null)"
                @click="toggleSub()">
              Projects
            </li>
            <li :class="{ active: hover === 'guestbook.js' || (!hover && $route.meta.menu === 'guestbook.js') }"
                @mouseenter="hoverMenu('guestbook.js')"
                @mouseleave="hoverMenu(null)">
              <router-link to="/guestbook">
                Guestbook
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="sub">
        <div class="sub-wrap">
          <ul :class="`active-${hover}`">
            <li class="s"
                @mouseenter="hoverMenu('about')"
                @mouseleave="hoverMenu(null)">
              <ul>
                <li>
                  <router-link to="/village">
                    Village
                  </router-link>
                </li>
                <li>
                  <router-link to="/goods">
                    Goods
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="s"
                @mouseenter="hoverMenu('participants')"
                @mouseleave="hoverMenu(null)">
              <ul>
                <li>
                  <router-link to="/designers">
                    Designers
                  </router-link>
                </li>
                <li>
                  <router-link to="/thanks">
                    Thanks to
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="s"
                @mouseenter="hoverMenu('projects')"
                @mouseleave="hoverMenu(null)">
              <ul>
                <li>
                  <router-link to="/projects/graphic">
                    Graphic Design
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/branding">
                    Branding & Identity
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/business">
                    Design Business
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/ux">
                    UX/UI
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="s"
                @mouseenter="hoverMenu('guestbook.js')"
                @mouseleave="hoverMenu(null)">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <Transition>
      <div v-if="showSearch && !$route.params.keyword"
           class="search-mobile">
        <div class="search">
          <input id="searchMobile"
                 type="text"
                 placeholder="Search"
                 @focus="toggleSearch(true)"
                 @blur="toggleSearch(false)"
                 @keyup.enter="search"
                 v-model="keyword">
          <button @click="search">
            <img src="@/assets/icon--search-blk.svg"
                 alt="검색" />
          </button>
        </div>
      </div>
    </Transition>

    <Transition>
      <div v-if="showNav"
           class="navigation-mobile">
        <div class="gnb">
          <button @click="toggleMobileNav">
            <img src="@/assets/icon--close.svg"
                 alt="메뉴 닫기" />
          </button>

          <ul class="main">
            <li class="main"
                :class="{ active: hover === 'about' || (!hover && $route.meta.menu === 'about') }"
                @click="hoverMenu('about')">
              <span>About</span>
              <ul class="sub">
                <li>
                  <router-link to="/village">
                    Village
                  </router-link>
                </li>
                <li>
                  <router-link to="/goods">
                    Goods
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="main"
                :class="{ active: hover === 'participants' || (!hover && $route.meta.menu === 'participants') }"
                @click="hoverMenu('participants')">
              <span>Participants</span>
              <ul class="sub">
                <li>
                  <router-link to="/designers">
                    Designers
                  </router-link>
                </li>
                <li>
                  <router-link to="/thanks">
                    Thanks to
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="main"
                :class="{ active: hover === 'projects' || (!hover && $route.meta.menu === 'projects') }"
                @click="hoverMenu('projects')">
              <span>Projects</span>
              <ul class="sub">
                <li>
                  <router-link to="/projects/graphic">
                    Graphic Design
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/branding">
                    Branding & Identity
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/business">
                    Design Business
                  </router-link>
                </li>
                <li>
                  <router-link to="/projects/ux">
                    UX/UI
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="main"
                :class="{ active: hover === 'guestbook.js' || (!hover && $route.meta.menu === 'guestbook.js') }"
                @click="hoverMenu('guestbook.js')">
              <router-link to="/guestbook">
                <span>Guestbook</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </Transition>

    <button class="to-top"
            :class="{ hide: isIndex || gnbHidden || scrollTop < 1 }"
            @click="toTop">
      <img src="@/assets/to-top.svg"
           alt="맨 위로" />
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'LayoutHeader',

  created () {
    this.init()
    window.addEventListener('scroll', this.handleScroll)
  },

  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  watch: {
    '$route.fullPath' () {
      this.init()
    },

    'gnbHidden' () {
      this.showSub = false
    }
  },

  methods: {
    ...mapMutations({
      setGnbHidden: 'setGnbHidden'
    }),

    init () {
      this.hover = null
      this.showSub = false
      this.showNav = false
      this.showSearch = !!this.$route.params.keyword
      setTimeout(() => {
        this.keyword = this.$route.params.keyword || ''
      }, 500)
    },

    toTop () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },

    hoverMenu (menuName) {
      this.hover = menuName
    },

    toggleSub (open = true) {
      this.showSub = open
    },

    toggleMobileNav () {
      this.showNav = !this.showNav
    },

    toggleSearch (open = true) {
      this.showSearch = open
    },

    handleScroll () {
      this.scrollTopNew = window.scrollY
      this.setGnbHidden(this.scrollTop < this.scrollTopNew)
      this.scrollTop = this.scrollTopNew
    },

    search () {
      if (this.keyword) {
        this.$router.push({ path: '/search/' + this.keyword })
      } else {
        const searchInput = document.getElementById('search')
        if (window.getComputedStyle(searchInput).display !== 'none') {
          document.getElementById('search').focus()
        } else {
          this.showSearch = true
          setTimeout(() => {
            document.getElementById('searchMobile').focus()
          }, 10)
        }
      }
    }
  },

  computed: {
    ...mapState({
      gnbHidden: 'gnbHidden'
    }),

    isIndex () {
      return (this.$route.matched && this.$route.matched[0].meta && this.$route.matched[0].meta.index) || false
    }
  },

  data () {
    return {
      hover: null,
      showSub: false,
      showNav: false,
      showSearch: false,
      keyword: '',

      scrollTop: 0,
      scrollTopNew: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  height: 90px;

  @media (max-width: 980px) {
    height: 50px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #ffffff;
    background-color: #000000;
    height: 90px;
    overflow: hidden;
    z-index: 9999;
    transition: height 0.5s cubic-bezier(0.65, 0, 0.35, 1),
                top 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateZ(0);

    &.hide {
      top: -90px;

      @media (max-width: 980px) {
        height: -50px;
      }
    }

    @media (max-width: 980px) {
      height: 50px;
    }

    &.expand {
      height: 310px;

      @media (max-width: 980px) {
        height: 50px;
      }
    }

    .gnb {
      height: 90px;

      @media (max-width: 980px) {
        height: 50px;
      }

      .gnb-wrap {
        max-width: 1612px;
        margin: 0 auto;
        padding: 0 60px;
        box-sizing: border-box;

        @media (max-width: 980px) {
          padding: 0 20px;
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .logo {
        float: left;

        img {
          margin: 35px 0;
          width: 222px;
          height: 20px;

          @media (max-width: 980px) {
            margin: 18px 0;
            width: 164px;
            height: 15px;
          }
        }
      }

      .hamburger {
        float: right;
        display: none;

        @media (max-width: 980px) {
          display: block;
        }

        img {
          margin: 15px 0;
          width: 24px;
          height: 20px;
        }
      }

      .search {
        float: right;
        width: 234px;
        height: 90px;
        position: relative;
        transition: opacity 0.5s ease;

        @media (max-width: 1280px) {
          width: 64px;
        }

        @media (max-width: 980px) {
          height: 50px;
          width: auto;
        }

        &.active {
          input {
            top: 26px;
          }
        }

        &.searching {
          @media (max-width: 1280px) {
            opacity: 0;
          }
        }

        input {
          -webkit-appearance: none;
          position: absolute;
          top: -40px;
          right: 0;
          border: 1px solid #707070;
          background: #000000;
          color: #FFFFFF;
          line-height: 37px;
          height: 37px;
          border-radius: 100px;
          width: 182px;
          padding: 0 20px;
          font-size: 15px;
          font-weight: 400;
          transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

          @media (max-width: 1280px) {
            display: none;
          }

          &::placeholder {
            color: #939393;
            font-size: 15px;
            font-weight: 500;
          }
        }

        button {
          position: absolute;
          top: 34px;
          right: 20px;
          display: block;

          &.v-enter-active,
          &.v-leave-active {
            transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);
          }

          &.v-enter-from,
          &.v-leave-to {
            top: 144px;

            @media (max-width: 1280px) {
              top: 104px;
            }
          }

          &.mobile {
            display: none;

            @media (max-width: 1280px) {
              display: block;
            }
          }

          &.web {
            @media (max-width: 1280px) {
              display: none;
            }
          }

          @media (max-width: 980px) {
            top: 14px;
          }

          img {
            width: 23px;
            height: 22px;
          }
        }
      }

      .navigation {
        float: right;
        position: relative;
        width: calc(100% - 480px);
        max-width: 750px;
        overflow: hidden;

        @media (max-width: 1280px) {
          width: calc(100% - 310px);
        }

        @media (max-width: 980px) {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          top: 85px;
          right: -25%;
          width: 23%;
          height: 5px;
          margin: 0 1%;
          background-color: #FFF16E;
          transition: right 0.3s cubic-bezier(0.65, 0, 0.35, 1);
          pointer-events: none;
        }

        &.active-guestbook::after {
          right: 0;
        }

        &.active-projects::after {
          right: 25%;
        }

        &.active-participants::after {
          right: 50%;
        }

        &.active-about::after {
          right: 75%;
        }
      }

      li {
        float: left;
        cursor: pointer;
        padding: 34px 0;
        font-size: 22px;
        text-transform: uppercase;
        width: 25%;
        text-align: center;
        transition: font-weight 0.3s ease;
        line-height: 22px;

        @media (max-width: 1280px) {
          font-size: 18px;
        }

        &.active {
          font-weight: 600;
          color: #FFF16E;
        }
      }

    }

    .sub {
      height: 220px;
      color: #ffffff;
      box-sizing: border-box;
      background-color: #191919;
      border-top: 1px solid #2e2e2e;

      @media (max-width: 980px) {
        display: none;
      }

      .sub-wrap {
        max-width: 1612px;
        margin: 0 auto;
        padding: 0 60px;
        box-sizing: border-box;
      }

      ul {
        float: right;
        position: relative;
        width: calc(100% - 480px);
        max-width: 750px;
        margin: 0 234px 0 0;

        @media (max-width: 1280px) {
          width: calc(100% - 310px);
          margin: 0 64px 0 0;
        }

        li.s {
          float: left;
          cursor: pointer;
          padding: 21px 0;
          font-size: 20px;
          line-height: 20px;
          width: 25%;
          transition: font-weight 0.3s ease;
          margin: 0;

          @media (max-width: 1280px) {
            font-size: 16px;
          }

          ul {
            width: auto;
            display: block;
            float: none;
            clear: both;
            margin: 0;

            li {
              text-align: center;
              white-space: nowrap;
              padding: 11px 0;
              font-weight: 200;

              a {
                transition: font-weight 0.3s ease;

                &:hover {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .search-mobile {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: none;

    @media (max-width: 1280px) {
      display: block;
    }

    &.v-enter-active,
    &.v-leave-active {
      transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }

    &.v-enter-from,
    &.v-leave-to {
      top: -100vh;
    }

    .search {
      position: relative;
      padding: 120px 60px;
      max-width: 1492px;
      margin: 0 auto;

      @media (max-width: 980px) {
        padding: 80px 20px;
      }

      input {
        -webkit-appearance: none;
        border: 1px solid #707070;
        background: #FFFFFF;
        color: #000000;
        line-height: 37px;
        height: 37px;
        border-radius: 100px;
        width: calc(100% - 40px);
        padding: 0 20px;
        font-size: 15px;
        font-weight: 400;

        &::placeholder {
          color: #939393;
          font-size: 15px;
          font-weight: 500;
        }
      }

      button {
        position: absolute;
        top: 128px;
        right: 80px;

        @media (max-width: 980px) {
          top: 88px;
          right: 40px;
        }

        img {
          width: 23px;
          height: 22px;
        }
      }
    }
  }

  .navigation-mobile {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    input {
      -webkit-appearance: none;
      position: absolute;
      top: -40px;
      right: 0;
      border: 1px solid #707070;
      background: #000000;
      color: #FFFFFF;
      line-height: 37px;
      height: 37px;
      border-radius: 100px;
      width: 182px;
      padding: 0 20px;
      font-size: 15px;
      font-weight: 400;
      transition: top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

      @media (max-width: 1280px) {
        display: none;
      }

      &::placeholder {
        color: #939393;
        font-size: 15px;
        font-weight: 500;
      }
    }

    &.v-enter-active,
    &.v-leave-active {
      transition: background-color 0.5s ease;

      .gnb {
        transition: right 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }

    &.v-enter-from,
    &.v-leave-to {
      background-color: rgba(0, 0, 0, 0);

      .gnb {
        right: -100%;
      }
    }

    .gnb {
      position: absolute;
      top: 0;
      right: 0;
      color: #FFFFFF;
      background-color: #000000;
      width: 82%;
      max-width: 496px;
      height: 100%;
      overflow: auto;

      button {
        position: absolute;
        top: 30px;
        right: 30px;

        img {
          width: 23px;
          height: 23px;
        }
      }

      ul.main {
        padding: 140px 0 50px;

        li.main {
          padding: 10px 50px;

          &.active {
            span {
              font-weight: 600;
              color: #FFF16E;

              &::after {
                left: 0;
              }
            }

            ul.sub {
              max-height: 200px;
              padding: 15px 0;
            }
          }

          span {
            position: relative;
            text-transform: uppercase;
            font-size: 25px;
            overflow: hidden;
            display: inline-block;
            padding: 12px 0;
            cursor: pointer;
            transition: font-weight 0.3s ease;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: -101%;
              width: 100%;
              height: 5px;
              margin: 0 1%;
              background-color: #FFF16E;
              transition: left 0.3s cubic-bezier(0.65, 0, 0.35, 1);
              pointer-events: none;
            }
          }

          ul.sub {
            max-height: 0;
            overflow: hidden;
            padding: 0;
            box-sizing: border-box;
            transition: max-height 0.5s cubic-bezier(0.65, 0, 0.35, 1),
                        padding 0.5s cubic-bezier(0.65, 0, 0.35, 1);

            li {
              font-size: 20px;
              padding: 10px 0;
              font-weight: 100;

              a {
                transition: font-weight 0.3s ease;

                &:hover {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }

  .to-top {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 9998;
    transition: bottom 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    @media (max-width: 980px) {
      bottom: 10px;
      right: 20px;
    }

    &.hide {
      bottom: -100px;

      @media (max-width: 980px) {
        bottom: -60px;
      }
    }

    img {
      @media (max-width: 980px) {
        height: 50px;
        width: 50px;
      }
    }
  }
}
</style>
